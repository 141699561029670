import TemplateSelections from './components/TemplateSelections/TemplateSelections';
import { useNavigate } from 'react-router';
import { routes } from './stores/RootStore';
import { rootStore } from './stores';
import { createSearchParams } from 'react-router-dom';
import { loadConfiguration } from './services/IntegrationService';
import React from 'react';
import { Loading } from './pages/Loading/Loading';
import { Locale } from './stores/Localization';


export const Controller: React.FC = () => {
  const navigate = useNavigate();
  const url = new URL(window.location as any);
  const params = url.searchParams;

  const loggedIn = params.get('u');
  const configurationId = params.get('configurationId');
  const productId = params.get('productId');
  const languageId = params.get('languageId');

  rootStore.setProductId(productId);

  if (loggedIn && loggedIn !== '0') {
    rootStore.authorize();
  } else {
    rootStore.unAuthorize();
  }

  /** Reference to all languageIds: https://learn.microsoft.com/en-us/openspecs/office_standards/ms-oe376/6c085406-a698-4e12-9d4d-c3b0ee3dbc4a
   *
   */

  let locale: Locale = Locale.en;
  switch (languageId) {
    case '1031':
    case '1070':
    case '2055':
    case '3079':
    case '4103':
    case '5127':
      locale = Locale.de;
      break;
    case '1043':
    case '1122':
    case '2067':
      locale = Locale.nl;
      break;
    case '1033':
      locale = Locale.en;
      break;
    case '1036':
    case '2060':
    case '3084':
    case '4108':
    case '5132':
    case '6156':
    case '7180':
    case '8204':
    case '9228':
    case '10252':
    case '11276':
    case '12300':
    case '13324':
    case '14348':
    case '15372':
    case '58380':
      locale = Locale.fr;
      break;
  }

  if (configurationId) {
    let navigateCallback: () => string | Promise<string> = () => '';
    switch (configurationId) {
      case 'WD000000':
        navigateCallback = () => routes.digitalWizard;
        break;
      case 'WM000000':
        navigateCallback = () => routes.mechanicalWizard;
        break;
      case 'AI000000':
        navigateCallback = () => routes.internaWizard;
        break;
      case 'AB000000':
        navigateCallback = () => routes.boxisWizard;
        break;
      default:
        navigateCallback = () => loadConfiguration(configurationId)
            .then((configurationURL) => {
              const params = rootStore.parseShareLink(configurationURL);
              if (!params.link) {
                console.error('Invalid configurationId', configurationId);
                return '';
              } else {
                return routes.configurator + '?' + createSearchParams(params);
              }
            });
    }

    // Localization must be done after navigation
      Promise.resolve()
      .then(navigateCallback)
      .then(url => url && navigate(url))
      .then(() => rootStore.localization.changeLanguage(locale))

    return <Loading />;
  }

  return <TemplateSelections />;
};
