import { action, computed, makeObservable, observable } from 'mobx';

import Localization, { Locale } from './Localization';
import { AsyncYamlDatasetService, YamlDatasetService } from '@canvas-logic/engine';
import { MediaPathResolver } from '../services/MediaPathResolver';
import { DatasetLoader } from '../services/DatasetLoader';
import { NotificationsStore } from './NotificationsStore';
import { ModelLoader } from '../viewer/ModelLoader';
import ResourceLoader from '../viewer/ResourceLoader';
import { TextRenderer } from '../viewer/TextRenderer';
import { ImageService } from '../services/ImageService';
import { ImagesLoader } from '../viewer/ImagesLoader';
import { contactService, ErrorDto, ErrorReport } from '../services/ContactService';

export enum DeviceType {
  DESKTOP,
  HANDLET
}

export class RootStore {
  private productId = '';

  localization = new Localization(Locale.en);
  datasetService: YamlDatasetService;
  isLoading = true;
  standalone: boolean;

  notificationsStore = new NotificationsStore();

  modelLoader: ModelLoader;
  resourceLoader: ResourceLoader;
  textRenderer: TextRenderer;
  imageService: ImageService;

  deviceType: DeviceType = DeviceType.HANDLET;
  authorized = false;
  isPdfSource = false;
  private errors: ErrorDto[] = [];
  private timerHandler = 0;
  private mediaPathResolver: MediaPathResolver;


  get isMobile(): boolean {
    return this.deviceType === DeviceType.HANDLET;
  }

  get isDesktop(): boolean {
    return this.deviceType === DeviceType.DESKTOP;
  }

  get isDeviceLowPerformant() {
    return this.isMobile || navigator.userAgent.toLowerCase().match(/mobile/i)
      || navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/);
  }

  constructor() {

    makeObservable(this, {
      isLoading: observable,
      deviceType: observable,
      authorized: observable,
      isPdfSource: observable,
      isMobile: computed,
      isDesktop: computed,
      setDeviceType: action.bound,
      init: action.bound,
      loading: action,
      loaded: action
    });
  }

  async init() {
    this.initErrorHandler();
    this.loading();
    await this.integrate();
    await this.localization.init();
    await this.initDatasetService();
    this.textRenderer = await TextRenderer.create('assets/fonts/Gudea_Regular.json');
    this.imageService = new ImageService(800, 600, this.resourceLoader, this.textRenderer);

    this.loaded();
  }

  loading() {
    this.isLoading = true;
  }

  loaded() {
    this.isLoading = false;
  }

  async initDatasetService() {
    this.mediaPathResolver = await MediaPathResolver.create();
    this.modelLoader = new ModelLoader(this.mediaPathResolver);
    this.resourceLoader = new ResourceLoader(this.modelLoader, new ImagesLoader());
    const { schema, data } = await DatasetLoader.load(this.mediaPathResolver);
    this.datasetService = await AsyncYamlDatasetService.create(
      schema,
      data
    );
  }

  enterDragging() {
    // prevent text selection while dragging
    document.body.classList.add('dragging');
  }

  leaveDragging() {
    //return text selection after dragging
    document.body.classList.remove('dragging');
  }

  setDeviceType(deviceType: DeviceType) {
    this.deviceType = deviceType;
  }

  private async integrate() {
    this.standalone = window.parent === window;
  }

  authorize() {
    this.authorized = true;
  }

  setPdfSource(isPdfSource: boolean) {
    this.isPdfSource = isPdfSource;
  }

  unAuthorize() {
    this.authorized = false;
  }

  parseShareLink(configurationURL: string): { link: string, v?: string } {
    const url = new URL(configurationURL);
    const queryIndex = url.hash.indexOf('?');
    const queryParams = new URLSearchParams(url.hash.slice(queryIndex));
    let result: { link: string, v?: string } = {
      link: queryParams.get('link') ?? ''
    };
    const v = queryParams.get('v');
    if (v && Number.isInteger(+v)) {
      result['v'] = v;
    }
    return result;
  }

  setProductId(productId: string | null) {
    this.productId = productId ?? '';
  }

  getProductId() {
    return this.productId;
  }

  private initErrorHandler() {
    window.onerror = (errMsg, url, line, column, err) => {
      const error: ErrorDto = {
        line: line ?? -1,
        column: column ?? -1,
        url: url ?? 'NA',
        message: errMsg.toString() + ' <||>\n' + err?.message ?? '',
        clientTime: new Date().toString()
      };
      console.error(error);
      this.errors.push(error);
      if (this.timerHandler) {
        clearTimeout(this.timerHandler);
      }
      this.timerHandler = window.setTimeout(() => {
        if (this.errors.length) {
          const errorReport: ErrorReport = {
            project: 'eSafe',
            desktop: this.isDesktop.toString(),
            mobile: this.isMobile.toString(),
            errors: this.errors
          };
          contactService.reportErrors(errorReport);
          this.errors = [];
        }
      }, 2000);
      return true;
    };
  }
}

export const routes = {
  digitalWizard: '/digital-wizard',
  mechanicalWizard: '/mechanical-wizard',
  internaWizard: '/interna-wizard',
  boxisWizard: '/boxis-wizard',
  generator: '/generator',
  configurator: '/configurator',
  generalWizard: '/wizard'
};
