import { IWall, IWizard, WallLocation, WallType } from '../schema';
import {
  centralBoxAt1600Height, finishingPlatesRule,
  idealFinishingPlatesRule,
  letterBoxesHeight,
  maximumHeightFromFloorRule,
  minimumHeightFromFloorRule,
  minimumHeightOfStandaloneRule,
  roofRule
} from "./rules";
import { LayoutRulesManager } from './LayoutRulesManager';
import { Layout } from '../layout/Layout';
import {
  centralBoxesHeight, standaloneMinHeight,
  standaloneMinSizeValidation,
  standaloneMinSizeViolation,
  zLayoutConstraintValidation,
  zLayoutMinShapeValidation
} from './wall-rules';
import { DomainRulesManager } from './DomainRulesManager';
import { cantAddCentralUnits, cantDeleteCentralUnits, cantDeleteMailboxUnits } from './domain-rules';


export class RulesManagerFactory {
  static createGeneratorRulesManager(wizard: IWizard, layout: Layout) {
    const self = new LayoutRulesManager(layout);
    self.addRule(centralBoxAt1600Height);
    self.addRule(letterBoxesHeight);
    if (wizard.wallLocation === WallLocation.OutsideUnsheltered) {
      self.addRule(roofRule);
    } else {
      self.addRule(finishingPlatesRule);
    }
    self.addRule(maximumHeightFromFloorRule);
    self.addRule(minimumHeightFromFloorRule);
    self.addRule(minimumHeightOfStandaloneRule);

    return self;
  }

  static createWallGeneratorRulesManager(wall: IWall, layout: Layout) {
    const self = new LayoutRulesManager(layout);
    if (wall.location !== WallLocation.OutsideUnsheltered) {
      self.addRule(idealFinishingPlatesRule);
    }

    return self;
  }

  static createWallRulesManager(wall: IWall, layout: Layout) {
    const self = new LayoutRulesManager(layout);
    self.addRule(zLayoutConstraintValidation);
    self.addRule(standaloneMinSizeViolation);
    if (wall.wallType === WallType.Mechanical || wall.wallType === WallType.Digital) {
      self.addRule(centralBoxesHeight);
    }
    self.addRule(letterBoxesHeight);
    if (wall.location === WallLocation.OutsideUnsheltered) {
      self.addRule(roofRule);
    }
    self.addRule(maximumHeightFromFloorRule);
    self.addRule(minimumHeightFromFloorRule);
    self.addRule(minimumHeightOfStandaloneRule);

    return self;
  }

  static createAlbaRulesManager(wall: IWall, layout: Layout) {
    const self = new LayoutRulesManager(layout);
    self.addRule(zLayoutConstraintValidation);
    self.addRule(standaloneMinSizeViolation);
    self.addRule(maximumHeightFromFloorRule);
    self.addRule(minimumHeightFromFloorRule);
    self.addRule(minimumHeightOfStandaloneRule);
    // if (wall.wallType === WallType.Mechanical || wall.wallType === WallType.Digital) {
    //   self.addRule(centralBoxesHeight);
    // }
    // self.addRule(letterBoxesHeight);
    // if (wall.location === WallLocation.OutsideUnsheltered) {
    //   self.addRule(roofRule);
    // }
    return self;
  }

  static createColumnRemovalPossibilityRulesManager(wall: IWall, layout: Layout) {
    const self = new LayoutRulesManager(layout);
    self.addRule(zLayoutMinShapeValidation);
    self.addRule(standaloneMinSizeValidation);
    return self;
  }

  static createRowRemovalPossibilityRulesManager(wall: IWall, layout: Layout): LayoutRulesManager {
    const self = new LayoutRulesManager(layout);
    self.addRule(maximumHeightFromFloorRule);
    self.addRule(standaloneMinHeight);

    return self;
  }

  static createDomainRulesManager() {
    const self = new DomainRulesManager();
    self.addRule(cantAddCentralUnits);
    self.addRule(cantDeleteCentralUnits);
    self.addRule(cantDeleteMailboxUnits);

    return self;
  }
}
